'use client'

import * as React from 'react'
import Script from 'next/script'

interface Props {
  children: React.ReactNode
}

export default (({ children }): React.ReactElement => {
  return (
    <>
      <Script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7490496871919293"
        crossOrigin="anonymous"
      />
      <Script
        type="text/javascript"
        src="https://platform-api.sharethis.com/js/sharethis.js#property=66698d5b6fc8ae001965d04d&product=image-share-buttons"
        async
      />
      <main className="relative mx-auto min-h-screen w-full">{children}</main>
    </>
  )
}) satisfies React.FC<Props>
